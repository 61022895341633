import createDataContext from "./createDataContext";
import {
  getPhysicianLocationsApi,
  getPhysiciansApi,
  getSingleCaseApi,
  pushCaseApi,
  queryEventsApi,
} from "../api/events-api";
import moment from "moment";

let initialState = {
  events: [],
  physicians: [],
  locations: [],
  selectedPhysician: null,
  selectedLocation: null,
  selectedWeek: {
    startDate: moment().startOf("week").add(1, "days").format("YYYYMMDDHHmmss"),
    endDate: moment()
      .endOf("week")
      .subtract(1, "days")
      .format("YYYYMMDDHHmmss"),
  },
  queryValue: null,
  pageToken: null,
  totalEvents: 0,
  isLoading: false,
  selectedCaseId: null,
  isModalOpen: false,
  isLoadingCase: false,
  currentCase: null,
};

const eventReducer = (state, action) => {
  switch (action.type) {
    case "set-events":
      return {
        ...state,
        events: action.payload,
      };
    case "set-more-events":
      return {
        ...state,
        events: [...state.events, ...action.payload],
      };
    case "set-physicians":
      return {
        ...state,
        physicians: action.payload,
      };
    case "set-physician-locations":
      return {
        ...state,
        locations: action.payload,
      };
    case "set-selected-physician":
      return {
        ...state,
        selectedPhysician: action.payload,
      };
    case "set-selected-location":
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case "set-selected-week":
      return {
        ...state,
        selectedWeek: action.payload,
      };
    case "set-query-value":
      return {
        ...state,
        queryValue: action.payload,
      };
    case "set-page-token":
      return {
        ...state,
        pageToken: action.payload.token,
        totalEvents: action.payload.total,
      };
    case "set-selected-case-id":
      return {
        ...state,
        selectedCaseId: action.payload,
      };
    case "set-modal-open":
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case "set-selected-case-details":
      return {
        ...state,
        currentCase: action.payload,
      };
    case "set-is-case-loading":
      return {
        ...state,
        isLoadingCase: action.payload,
      };
    case "set-loading-events":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "error":
      console.log("error :: ", action.payload);
      return {
        ...state,
        errorMessage: action.payload,
        statusMessage: "",
      };
    default:
      return {
        ...state,
      };
  }
};

const queryEvents = (dispatch) => async (uri) => {
  dispatch({
    type: "set-loading-events",
    payload: true,
  });
  try {
    const response = await queryEventsApi(uri);
    if (response) {
      if (response.data && response.headers) {
        dispatch({ type: "set-events", payload: response.data });
      }
      if (response.headers && response.config) {
        if (response.headers["x-next-page-token"]) {
          dispatch({
            type: "set-page-token",
            payload: {
              token: response.headers["x-next-page-token"],
              total: parseInt(response.headers["x-num-found"]),
            },
          });
        }
      }
      dispatch({
        type: "set-loading-events",
        payload: false,
      });
      return response;
    }
  } catch (error) {
    return dispatch({ type: "error", payload: "Could not get physicians" });
  }
};

const getMoreEvents = (dispatch) => async (uri) => {
  try {
    const response = await queryEventsApi(uri);
    if (response) {
      if (response.data && response.headers) {
        if (
          response.headers["x-next-page-token"] &&
          response.headers["x-num-found"]
        ) {
          dispatch({
            type: "set-page-token",
            payload: {
              token: response.headers["x-next-page-token"],
              total: parseInt(response.headers["x-num-found"]),
            },
          });
        }
        return dispatch({ type: "set-more-events", payload: response.data });
      }

      return response;
    }
  } catch (error) {
    return dispatch({ type: "error", payload: "Could not get physicians" });
  }
};

const getPhysicians = (dispatch) => async () => {
  try {
    const response = await getPhysiciansApi();
    if (response) {
      return dispatch({ type: "set-physicians", payload: response });
    }
  } catch (error) {
    return dispatch({ type: "error", payload: "Could not get physicians" });
  }
};

const getPhysicianLocations = (dispatch) => async (physicianId) => {
  try {
    const response = await getPhysicianLocationsApi(physicianId);
    if (response) {
      return dispatch({ type: "set-physician-locations", payload: response });
    }
  } catch (error) {
    return dispatch({ type: "error", payload: "Could not get physicians" });
  }
};

const getCase = (dispatch) => async (eventId) => {
  dispatch({
    type: "set-is-case-loading",
    payload: true,
  });
  try {
    const response = await getSingleCaseApi(eventId);
    if (response && response.event && response.event.length > 0) {
      dispatch({
        type: "set-selected-case-details",
        payload: response.event[0],
      });
    }
    dispatch({
      type: "set-is-case-loading",
      payload: false,
    });
    return response;
  } catch (error) {
    return dispatch({ type: "error", payload: "Could not case details" });
  }
};

const pushCase = (dispatch) => async (eventId) => {
  try {
    const response = await pushCaseApi(eventId);
    if (response) {
      console.log("success");
    }

    return response;
  } catch (error) {
    return dispatch({ type: "error", payload: "Could not case details" });
  }
};

const setSelectedPhysician = (dispatch) => (physician) => {
  return dispatch({ type: "set-selected-physician", payload: physician });
};

const setSelectedLocation = (dispatch) => (location) => {
  return dispatch({ type: "set-selected-location", payload: location });
};

const setSelectedWeek = (dispatch) => (week) => {
  return dispatch({ type: "set-selected-week", payload: week });
};

const setQueryValue = (dispatch) => (query) => {
  return dispatch({ type: "set-query-value", payload: query });
};

const setSelectedCaseId = (dispatch) => (eventId) => {
  return dispatch({ type: "set-selected-case-id", payload: eventId });
};

const setIsModalOpen = (dispatch) => (isOpen) => {
  return dispatch({ type: "set-modal-open", payload: isOpen });
};

const setSelectedCaseDetails = (dispatch) => (event) => {
  return dispatch({ type: "set-selected-case-details", payload: event });
};

const setEvents = (dispatch) => (events) => {
  return dispatch({ type: "set-selected-case-details", payload: events });
};

export const { Provider, Context } = createDataContext(
  eventReducer,
  {
    queryEvents,
    getMoreEvents,
    getPhysicians,
    getPhysicianLocations,
    getCase,
    pushCase,
    setSelectedPhysician,
    setSelectedLocation,
    setSelectedWeek,
    setQueryValue,
    setSelectedCaseId,
    setIsModalOpen,
    setSelectedCaseDetails,
    setEvents,
  },
  initialState
);
