import React, { useContext, useEffect } from "react";
import { Context as EventContext } from "../../context/EventContext";
import moment from "moment";
import "./CaseModal.scss";
import DocSperaButton from "../DocSperaButton/DocSperaButton";
import DocSperaLoading from "../LoadingIndicator/DocSperaLoading";

function CaseModal(props) {
  const { state: eventState, getCase } = useContext(EventContext);
  const currentCase = eventState.currentCase;

  useEffect(() => {
    if (eventState.selectedCaseId) {
      getCase(eventState.selectedCaseId);
    }
  }, [eventState.selectedCaseId]);

  return (
    <>
      <div className="case-modal">
        {eventState.isLoadingCase ? (
          <div className="center-container">
            <DocSperaLoading isDataLoading={false} setPageLoaded={() => {}} />
          </div>
        ) : currentCase ? (
          <div className="modal-body text-start">
            <div className="top-section">
              <div className="box">
                <h5>Case Details</h5>
                <div
                  className="row"
                  style={{ color: currentCase?.case ? "black" : "red" }}
                >
                  <div className="col-3">
                    <b>Case Name:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.case !== "Undefined" ? currentCase?.case : ""}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.procedure !== "Undefined" ? "black" : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Procedure:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.procedure !== "Undefined"
                      ? currentCase?.procedure
                      : ""}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.location && currentCase?.location.name
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Location:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.location && currentCase?.location.name
                      ? currentCase?.location.name
                      : "N/A"}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.start && currentCase?.end ? "black" : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Time:</b>
                  </div>
                  <div className="col-8">
                    {moment(currentCase?.start).format("dddd, MMM D, YYYY")} at{" "}
                    {moment(currentCase?.start).format("hh:mm A")} -{" "}
                    {moment(currentCase?.end).format("hh:mm A")}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color: currentCase?.orientation ? "black" : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Orientation:</b>
                  </div>
                  <div className="col-8">{currentCase?.orientation}</div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.physicians &&
                      currentCase?.physicians.length > 0
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Physician(s):</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.physicians &&
                      currentCase?.physicians.length > 0 &&
                      currentCase?.physicians.map((p) => (
                        <div key={p.npi}>
                          {p.name}, NPI:{p.npi}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <b>Reps:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.reps &&
                      currentCase?.reps.length > 0 &&
                      currentCase?.reps.map((r) => (
                        <div key={r.id}>{r.name}</div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="box">
                <h5>Patient Details</h5>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.patient &&
                      currentCase?.patient.name &&
                      currentCase?.patient.name.first &&
                      currentCase?.patient.name.last
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Name:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.patient &&
                    currentCase?.patient.name &&
                    currentCase?.patient.name.first &&
                    currentCase?.patient.name.last
                      ? currentCase?.patient.name.first +
                        " " +
                        currentCase?.patient.name.last
                      : "N/A"}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.patient && currentCase?.patient.mrn
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>MRN:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.patient && currentCase?.patient.mrn
                      ? currentCase?.patient.mrn
                      : "N/A"}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.demographics && currentCase?.demographics.age
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Age:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.demographics && currentCase?.demographics.age
                      ? currentCase?.demographics.age
                      : "N/A"}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.demographics &&
                      currentCase?.demographics.gender !== "Unknown"
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Gender:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.demographics &&
                    currentCase?.demographics.gender !== "Unknown"
                      ? currentCase?.demographics.gender
                      : "N/A"}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.demographics &&
                      currentCase?.demographics.height
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Height:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.demographics &&
                    currentCase?.demographics.height
                      ? currentCase?.demographics.height
                      : "N/A"}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.demographics &&
                      currentCase?.demographics.weight
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Weight:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.demographics &&
                    currentCase?.demographics.weight
                      ? currentCase?.demographics.weight
                      : "N/A"}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.study && currentCase?.study.length > 0
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Dicoms:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.study && currentCase?.study.length > 0
                      ? currentCase?.study.map((s, idx) => (
                          <a href={s.url} key={s.id} className="dicom-link">
                            {s.name}
                            {idx !== currentCase?.study.length - 1 ? ", " : ""}
                          </a>
                        ))
                      : "N/A"}
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    color:
                      currentCase?.templated && currentCase?.templated
                        ? "black"
                        : "red",
                  }}
                >
                  <div className="col-3">
                    <b>Templated-Img:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.templated ? "Templated-Img" : "N/A"}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <b>Planned Parts:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.equipment &&
                    currentCase?.equipment.planned !== null
                      ? currentCase?.equipment.planned.map((a, idx) => (
                          <li key={a.name + idx}>{a.name}</li>
                        ))
                      : ""}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <b>Actual Parts:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.equipment &&
                    currentCase?.equipment.actual !== null
                      ? currentCase?.equipment.actual.map((a, idx) => (
                          <li key={a.name + idx}>{a.name}</li>
                        ))
                      : ""}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <b>Devices:</b>
                  </div>
                  <div className="col-8">
                    {currentCase?.equipment &&
                    currentCase?.equipment.devices !== null
                      ? currentCase?.equipment.devices.map((a, idx) => (
                          <li key={a.name + idx}>{a.name}</li>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <h5>Images in Pacs</h5>
              <table className="table table-md mt-2">
                <thead>
                  <tr>
                    <th>Study Title</th>
                    <th>Modality</th>
                    <th>Thin Study</th>
                    <th>Study Date</th>
                    <th>Upload Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentCase?.studies_in_pacs &&
                    currentCase?.studies_in_pacs.length > 0 &&
                    currentCase?.studies_in_pacs.map((study, idx) => (
                      <tr key={study.name + idx}>
                        <td>{study.name ? study.name : ""}</td>
                        <td>{study.modality ? study.modality : ""}</td>
                        <td>{study.thin ? "Yes" : "No"}</td>
                        <td>
                          {study.date_taken
                            ? moment(study.date_taken).format("MM-DD-YYYY")
                            : ""}
                        </td>
                        <td>
                          {study.date_uploaded
                            ? moment(study.date_uploaded).format("MM-DD-YYYY")
                            : ""}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="center-container">Case Details Not Found</div>
        )}
      </div>

      <div className="modal-footer">
        <DocSperaButton
          className="secondary"
          label="Close"
          customPadding={".25rem .5rem"}
          onPress={() => props.closeModal()}
        />
        <DocSperaButton
          className="primary"
          label="Resend Case"
          customPadding={".25rem .5rem"}
          onPress={() => props.pushCase(currentCase?.event_id)}
        />
      </div>
    </>
  );
}

export default CaseModal;
