import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import NavBar from "../../components/NavBar/NavBar";
import Calendar from "../Calendar/Calendar";
import { Provider as AuthProvider } from "../../context/AuthContext";
import { Provider as GlobaleProvider } from "../../context/GlobalContext";
import { Provider as EventProvider } from "../../context/EventContext";

function App() {
  return (
    <AuthProvider>
      <GlobaleProvider>
        <EventProvider>
          <div className="App">
            <NavBar />
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Calendar {...props} />}
              />
            </Switch>
          </div>
        </EventProvider>
      </GlobaleProvider>
    </AuthProvider>
  );
}

export default App;
