import styled from "styled-components";
import { Icon } from "../Icon/Icon";

const Button = styled.button`
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  position: relative;
  transition: 0.3s;
  height: fit-content;
  align-items: center;
  max-height: 43px;
  justify-content: center;
  border: 1px solid;
  font-size: 16px;
  padding: 8px 18px;
  width: fit-content;

  .text {
    text-wrap: nowrap;
  }

  .icon {
    padding: 8px 12px !important;
    margin-right: ${(props) => (props.$hasLabel ? "5px" : "0")};
  }

  &.primary {
    background-color: #0096fa;
    border-color: transparent;
    color: #fff;

    &:hover {
      background-color: #b1e0ff;
    }
  }

  &.secondary {
    background-color: #fff;
    border-color: #0096fa;
    color: #0096fa;

    &:hover {
      background-color: #0096fa;
      color: #fff;
    }
  }

  &.red {
    border-color: #e23118;
  }

  &.secondary.red {
    color: #e23118;

    .icon {
      color: #e23118;
    }

    &:hover {
      background-color: #e23118;
      color: #fff;
    }
  }

  &.primary.red {
    background-color: #e23118;
    border-color: transparent;
    color: #fff;

    &:hover {
      background-color: #ff634d;
      color: #fff;
    }
  }

  &:hover {
    .icon {
      filter: brightness(0) invert(1);
    }
  }

  &:disabled {
    background-color: #ffffff;
    cursor: not-allowed;
    // border-style: none;
    pointer-events: none;

    border: 1px solid #9f9f9f;
    .text {
      -webkit-text-fill-color: #9f9f9f;
    }
  }
`;

export default function DocSperaButton({
  id,
  label = undefined,
  icon,
  onPress = () => {},
  height = "20px",
  width = "20px",
  disabled = false,
  className,
  customPadding = undefined,
}) {
  return (
    <Button
      id={id}
      disabled={disabled}
      $hasLabel={label !== undefined}
      onClick={onPress}
      className={className}
      style={
        !label
          ? { padding: "12px 12px" }
          : customPadding
          ? { padding: customPadding }
          : {}
      }
    >
      {icon && (
        <Icon
          url={icon}
          height={height}
          width={width}
          color={
            className?.includes("red")
              ? "#e23118"
              : className?.includes("primary") && !disabled
              ? "#ffffff"
              : disabled
              ? "#9f9f9f"
              : "#0096fa"
          }
          customClass="icon"
        />
      )}
      <span className="text">{label}</span>
    </Button>
  );
}
