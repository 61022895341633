import axios from "axios";
import { getCookie } from "../helpers";

const instance = axios.create({
  baseURL: "/",
  withCredentials: true, // todo: make this configurable
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${getCookie("docsperajwt")}`;
  return request;
};

instance.interceptors.request.use((request) => requestHandler(request));

async function queryEventsApi(uri) {
  return instance
    .get(`/json/events?${uri}`)
    .then((response) => {
      // process the response
      return new Promise((resolve, reject) => {
        if (response) {
          let json = response;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log("error"); //.warn .write
    });
}

async function getPhysiciansApi() {
  return instance
    .get("/json/physicians")
    .then((response) => {
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log("error"); //.warn .write
    });
}

async function getPhysicianLocationsApi(physicianId) {
  return instance
    .get(`/json/physicians/${physicianId}/locations`)
    .then((response) => {
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log("error"); //.warn .write
    });
}

async function getSingleCaseApi(eventId) {
  return instance
    .get(`/json/event-scheduling?event_id=${eventId}`)
    .then((response) => {
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log("error"); //.warn .write
    });
}

async function pushCaseApi(eventId) {
  return instance
    .put(`/grpc/push/${eventId}`)
    .then((response) => {
      // process the response
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log("error"); //.warn .write
    });
}

export {
  queryEventsApi,
  getPhysiciansApi,
  getPhysicianLocationsApi,
  getSingleCaseApi,
  pushCaseApi,
};
